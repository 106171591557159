
/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------              branding.scss                 ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */

// GRID BREAKPOINTS
//    xs: 0,
//    sm: 576px,
//    md: 790px,
//    lg: 992px,
//    xl: 1200px,
//    xxl: 1400px

// -------------------------------
// COLOURCODE NAMES (fed from _colours.scss)
// -------------------------------
.brand1 { background-color: $brand1; }
.brand1-light { background-color: $brand1-light; }
.brand1-lighter { background-color: $brand1-lighter; }
.brand1-very-light { background-color: $brand1-very-light; }
.brand1-dark { background-color: $brand1-dark; }
.brand1-darker { background-color: $brand1-darker; }
.brand1-border { background-color: $brand1-border; }
.brand2 { background-color: $brand2; }
.brand2-light { background-color: $brand2-light; }
.brand2-lighter { background-color: $brand2-lighter; }
.brand2-very-light { background-color: $brand2-very-light; }
.brand2-dark { background-color: $brand2-dark; }
.brand2-darker { background-color: $brand2-darker; }
.brand2-border { background-color: $brand2-border; }
.brand3 { background-color: $brand3; }
.brand3-light { background-color: $brand3-light; }
.brand3-lighter { background-color: $brand3-lighter; }
.brand3-very-light { background-color: $brand3-very-light; }
.brand3-dark { background-color: $brand3-dark; }
.brand3-darker { background-color: $brand3-darker; }
.brand3-border { background-color: $brand3-border; }
.text-brand1 { color: $brand1; }
.text-brand2 { color: $brand2; }
.text-brand3 { color: $brand3; }
.text-white { color: $white; }
.text-black { color: $black; }

// -------------------------------
// BUTTONS
// -------------------------------
.btn { font-weight: $body-font-weight; }
.btn-default, .btn-default:hover { color: $black; border: 1px solid #ccc;}
.btn-default:hover { border-color: #555;}
.btn-primary, .btn-primary:hover { color: $btn-primary-colour; }
.btn-success, .btn-success:hover { color: $btn-success-colour; }
.btn-info, .btn-info:hover { color: $btn-info-colour; }
.btn-warning, .btn-warning:hover { color: $btn-warning-colour; }
.btn-danger, .btn-danger:hover { color: $btn-danger-colour; }

// -------------------------------
// GENERAL TYPOGRAPHY - wrap styles with .ccm-page to isolate them from the admin styles
// -------------------------------
body, p, ul, dl, ul.tick-list { font-size: $font-size-base-px * $font-size-reduction-phones; }
h1, .h1 { font-size: $h1-font-size * $font-size-reduction-phones; font-family: $font-family-header; font-style: italic; } // color: darken($brand2, 5%); }
h2, .h2 { font-size: $h2-font-size * $font-size-reduction-phones; font-family: $font-family-header; font-style: italic; }
h3, .h3 { font-size: $h3-font-size * $font-size-reduction-phones; font-family: $font-family-header; font-style: italic; }
h4, .h4, .panel-title { font-size: $h4-font-size * $font-size-reduction-phones; font-family: $font-family-header; }
.table, .table ul, .pagination, label, .label, .inline-checkboxes, .inline-radios, .popover-content, .tooltip-inner { font-size: ($font-size-base-px * $font-size-reduction-phones * 0.9); }
small, .small, .small p, .small-text { font-size: $font-size-small; }
.small-text p { font-size: $font-size-small; line-height: 1.4; margin-bottom: 15px; }
large, .large { font-size: $font-size-large; }
@media (min-width: $grid-breakpoint-xxs) { 
    .table, .table ul, .pagination, label, .label, .inline-checkboxes p, .inline-radios p, .popover-content, .tooltip-inner { font-size: $font-size-base-px * 0.95; }
}
@include media-breakpoint-up(sm) { 
    body, p, ul, dl, ul.tick-list { font-size: $font-size-base-px * $font-size-reduction-tablets; }
    h1, .h1 { font-size: $h1-font-size * $font-size-reduction-tablets; }
    h1:after, .h1:after, footer h2:after, footer h3:after { content: ""; height: 3px; display: block; bottom: 0; position: relative;
        background-color: $brand1-light; background: linear-gradient(to left, rgba(172, 20, 3, 0) 0%, rgba(172, 20, 3, 0.6) 100%); }
    h2, .h2 { font-size: $h2-font-size * $font-size-reduction-tablets; }
    h3, .h3 { font-size: $h3-font-size * $font-size-reduction-tablets; }
    h4, .h4, .panel-title { font-size: $h4-font-size * $font-size-reduction-tablets; }
}
@include media-breakpoint-up(md) { 
    body, p, ul, dl, ul.tick-list { font-size: $font-size-base-px; }
    h1, .h1 { font-size: $h1-font-size; }
    h2, .h2 { font-size: $h2-font-size; }
    h3, .h3 { font-size: $h3-font-size; }
    h4, .h4, .panel-title { font-size: $h4-font-size; }
} 
body, p, ul, dl, .popover-content { font-weight: $body-font-weight; }
b, strong, .bold, .popover-title,
p b, p strong, dt, th, td.bold { font-family: $font-family-header; font-weight: $headings-font-weight; color: $bold-color; }
label, .label { font-family: $font-family-header; font-weight: $headings-font-weight; }
.radio label, .checkbox label, .radio-inline, .checkbox-inline, option { font-family: $font-family-header; font-weight: $headings-font-weight; }
h1, .h1, h2, .h2, h3, .h3 { margin-top: $headings-123-margin-top; margin-bottom: $headings-123-margin-bottom; }
h1 em { font-size: $h3-font-size; font-weight: $body-font-weight; color: #aaa; }
.col-content a b, .col-content a strong { color: $link-color; } // don't colour the bold nav links
p { margin-bottom: $para-margin-bottom; }
footer { color: $footer-color; }
footer a, footer strong, footer b { color: $footer-color; }
footer .social a { color: $footer-color; }
footer a:hover { color: $footer-hover; }
footer h1, footer h2, footer h3 { font-size: $h3-font-size; color: $footer-color; padding-bottom: 5px; margin: 5px 0; }
.credit { color: $footer-color; font-size: $font-size-small; }
.credit a { color: $footer-color; }
// links
a { text-decoration: none; }
a:hover { text-decoration: underline; }
.btn:hover { text-decoration: none; }
//small titles
h1 .small, h1 small, h2 .small, h2 small, h3 .small, h3 small, h4 .small, h4 small { font-size: 65%; font-weight: $headings-font-weight; line-height: 1; color: #999; }

// remove top margin if header is first item in a content box or custom style container
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h1:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .col-pad-left-10 > .container:first-of-type > .row > .col-md-12 > h3:first-child, 
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h1:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .col-pad-right-10 > .container:first-of-type > .row > .col-md-12 > h3:first-child,
.col-content > .col-pad-left-10 > h1:first-child,
.col-content > .col-pad-left-10 > h2:first-child,
.col-content > .col-pad-left-10 > h3:first-child, 
.col-content > .col-pad-right-10 > h1:first-child,
.col-content > .col-pad-right-10 > h2:first-child,
.col-content > .col-pad-right-10 > h3:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h1:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h2:first-child,
.col-content > .container:first-of-type > .row > .col-md-12 > h3:first-child,
.col-content > h1:first-child,
.col-content > h2:first-child,
.col-content > h3:first-child,
.col-sidebar > h1:first-child,
.col-sidebar > h2:first-child,
.col-sidebar > h3:first-child,
.ccm-custom-style-container > h1:first-child,
.ccm-custom-style-container > h2:first-child,
.ccm-custom-style-container > h3:first-child { margin-top: 0; }


// -------------------------------
// GENERAL LAYOUT
// -------------------------------
section.mainbody { overflow-x: hidden; } // prevent side space on mobiles when using AOS
//header .navbar { border-bottom: none; }
.navbar { padding: 0; }
//header .branding img { margin: 0 auto; } // width: 70%; padding: 3px 0 3px 5%;
main { padding-top: $space-for-phone-header; } // allow space for phone fixed header. Add border to give structure
#ccm-page-controls-wrapper > #ccm-toolbar { z-index: 1040; } // keep toolbar to front
.footer-top { position: relative; height: 0; background: none; }
footer { position: relative; padding: 20px 0; background: $tarmac-tile-dark; border-top: 5px solid #ce1707; }
footer .footer-main { padding-top: 15px; padding-bottom: 10px; border-bottom: 1px solid $footer-color; }
footer .footer-bottom { padding-top: 20px; padding-bottom: 10px; }
// main page sections
section.topbody { margin: 20px 0; }
section.mainbody { margin: 20px 0 0 0; padding-bottom: 20px; position: relative; } // use padding to keep a background
section.bottombody { margin: -10px 0 0 0; padding-bottom: 20px; position: relative; background-color: $body-bg; } // use padding to keep a background
@include media-breakpoint-up(md) {
    main { padding-top: $space-for-tablet-header; }
    section.topbody { margin: 20px 0; }
    section.mainbody { margin: 20px 0 0 0; padding-bottom: 30px; } // use padding to keep a background
    section.bottombody { margin: -20px 0 0 0; padding-bottom: 30px; } // use padding to keep a background
}
@include media-breakpoint-up(lg) {
    main { padding-top: $space-for-medium-header; }
    section.topbody { margin: 25px 0; }
    section.mainbody { margin: 25px 0 0 0; padding-bottom: 35px; } // use padding to keep a background
    section.bottombody { margin: -25px 0 0 0; padding-bottom: 35px; } // use padding to keep a background
}
@include media-breakpoint-up(xl) {
    main { padding-top: $space-for-large-header; }
    section.topbody { margin: 30px 0; }
    section.mainbody { margin: 30px 0 0 0; padding-bottom: 40px; } // use padding to keep a background
    section.bottombody { margin: -30px 0 0 0; padding-bottom: 40px; } // use padding to keep a background
}
// extend width of item
.extend-width, 
.extend-width .ccm-image-slider-container { position: relative; box-sizing: border-box; left: 50%; width: 100vw; min-width: 100%; padding: 0; margin-left: -50vw; max-width: none; }
.extend-width > div { width: 100%; }
// skip content link
a#skiptocontent { position: absolute; top: -40px; left: 0px; opacity: 0; padding: 6px 10px; color: $white; background: #BF1722; border-right: 1px solid white; border-bottom: 1px solid white; border-bottom-right-radius: 8px; transition: top 0.3s ease-out; z-index: 100; }
a#skiptocontent:focus, a#skiptocontent:active { top: 0px; opacity: 1; }

// move top nav menu under toolbar when logged in
html.ccm-toolbar-visible .navbar.fixed-top { top: $height-of-admin-toolbar; }

// adjust layout of some elements in edit mode so that we can access all of the edit boxes
html.ccm-toolbar-visible.ccm-edit-mode #top.ccm-page > .ccm-global-area:nth-of-type(1) { margin-top: $space-above-nav-large + $navbar-height-normal + 48; }
html.ccm-toolbar-visible.ccm-edit-mode #wrap { padding-top: 0; }


// -------------------------------
// HOME PAGE
// -------------------------------
.home-pg-bar { min-height: 55px; background-color: $brand1; margin-top: -10px; } // red bar (top margin allows for a little movement)
.home-pg-bar p { font-size: 20px; color: $white; text-align: center; padding-top: 11px; margin-bottom: 10px; } // text in red bar
.home-lower { margin-top: 0px; } // lower section
.home-lower-title { margin: 27px 0 -5px; text-align: center; } // button container
.home-lower-title .btn { font-size: 1em; } // buttons
//.home-lower .feature-scroll a.feature-link h4 { font-style: italic; } // feature titles
.home-lower .feature-scroll a.feature-link p { line-height: $line-height-base; } // feature text
.home-bottom-title { padding: 20px 0 5px; margin: -10px 0 30px; text-align: center; } // bottom header/strap-line
.home-bottom-title h1 { font-size: $font-size-large; text-align: center; color: $brand1-light; font-style: italic; font-weight: $body-font-weight; margin: 0; }
.home-bottom-title h1:after { display: none; }
// fix the banner image and 'did you know' sections
#wrap section.fixed-position { position: fixed; top: $navbar-height-phone - $move-banner-up-phone; left: 0; right: 0; margin: 0 auto; } // slider image
#wrap.logged-in section.fixed-position { top: $navbar-height-phone - $move-banner-up-phone + $height-of-admin-toolbar; }
// crop to middle (disable when editing as it throws out the admin editing blocks)
html:not(.ccm-edit-mode) .crop-to-middle { display: flex; flex-wrap: wrap; overflow-x: hidden; }
html:not(.ccm-edit-mode) .crop-to-middle div { height: $banner-height-phone; position: relative; width: 100%; flex: 0 0 auto; }
html:not(.ccm-edit-mode) .crop-to-middle div img { position: absolute; top: 0; bottom: 0; left: 0; right: 0; height: 100%; width: 100%; object-fit: cover; object-position: center; }
// position the content that scrolls up over the image
$margin-top: $banner-height-phone + $space-above-nav-phone - $move-banner-up-phone;
#wrap section.move-over-fixed { position: relative; background: $body-bg; margin-top: $margin-top; padding-top: 0; }
.home-main-pic { margin-top: $space-above-banner-phone; background-color: #455d2d; }
.home-main-pic picture { margin: 0 auto 0; }

// adjust the position of the main picture to best suit the various picture-frame sizes
@media (min-width: 430px) { .home-main-pic picture { margin-top: -20px; } }

@include media-breakpoint-up(sm) { // 576px
    html:not(.ccm-edit-mode) .crop-to-middle div { height: $banner-height-tablet; }
    $margin-top: $banner-height-tablet + $space-above-banner-tablet - $move-banner-up-phone;
    #wrap section.move-over-fixed { margin-top: $margin-top; } // padding-top = space under banner
    .home-main-pic { margin-top: $space-above-banner-tablet; }
    .home-pg-bar p { font-size: 22px; } // text in red bar
    .home-lower { margin-top: 10px; } // lower section
    .home-lower-title { margin-top: 35px; } // button container
    .home-main-pic picture { margin-top: 0px; }
}

@media (min-width: 670px) and (max-width: 789px) { .home-main-pic picture { margin-top: -40px; } }

@include media-breakpoint-up(md) { // 790px
    #wrap section.fixed-position { top: $navbar-height-phone; } // slider image
    #wrap.logged-in section.fixed-position { top: ($navbar-height-phone + $height-of-admin-toolbar); }
    html:not(.ccm-edit-mode) .crop-to-middle div { height: $banner-height-medium; }
    $margin-top: $banner-height-medium - $space-above-nav-medium;
    #wrap section.move-over-fixed { margin-top: $margin-top; } // padding-top = space under banner
    .home-lower { margin-top: 20px; } // lower section
    .home-lower .feature-scroll a.feature-link h4 { font-size: 1em; } // feature titles
    .home-lower .feature-scroll a.feature-link p { font-size: 0.9em; } // feature text
    .home-bottom-title h1 { font-size: $font-size-xlarge; padding-top: 30px; } // bottom header/strap-line
}

@include media-breakpoint-up(lg) { // 992px
    .home-pg-bar { margin-top: -30px; } // red bar (top margin allows for a little movement)
    html:not(.ccm-edit-mode) .crop-to-middle div { height: $banner-height-large; }
    $margin-top: $banner-height-large - $space-above-nav-large; // without text boxes
    #wrap section.move-over-fixed { margin-top: $margin-top; } // padding-top = space under banner
    .home-pg-bar p { font-size: 28px; }
    .home-lower-title { margin-top: 50px; } // button container
    .home-lower { margin-top: 30px; } // lower section
    .home-lower .feature-scroll a.feature-link h4 { font-size: 1.1em; } // feature titles
    .home-lower .feature-scroll a.feature-link p { font-size: 0.95em; } // feature text
}

@include media-breakpoint-up(xl) { // 1200px
    .home-pg-bar { margin-top: -20px; } // red bar (top margin allows for a little movement)
    html:not(.ccm-edit-mode) .crop-to-middle div { height: $banner-height-xlarge; }
    $margin-top: $banner-height-xlarge - $space-above-nav-xlarge; // without text boxes
    #wrap section.move-over-fixed { margin-top: $margin-top; } // padding-top = space under banner
    // limit the width of the image block image to it's size of 1600px
    .home-main-pic picture { margin-top: -30px; }

}

@media (min-width: 1500px) { .home-main-pic picture { margin-top: -80px; } }

.page-template-home .share-icons hr { display: none; } // hide h. line above share icons


// -------------------------------
//  TIDY PAGES FOR PRINT
// -------------------------------
@media print {
    @page { size: A4 portrait; margin: 10mm; }
    // do not print particular page items
    .noprint, #ccm-toolbar, header, .footer-main,
    .youtubeBlock, .slideshareBlock, .side-nav, 
    .button-bar { display: none !important; }
	// do not print link URLs
    a[href]:after { content: none !important; }
    img { float: none !important; position: static !important; max-width: 100% !important; }
    // stop home page banner printing on every page, and tighten up spacing
    .fixed-position { position: relative !important; top: 0 !important; }
    .move-over-fixed, .mainbody, footer { padding: 0 !important; margin: 0 !important; }
}


// -------------------------------
//  IMAGES
// -------------------------------
// global responsive class for all main content images (but not portholes)
.col-content img, .col-content div:not(.porthole) img, .col-sidebar img, .col-sidebar div:not(.porthole) img { max-width: 100%; height: auto; }
.blog-header-img > picture > img { width: 100%; height: auto; } // push blog header images to full width if needed
picture > img.ccm-image-block.img-fluid { width: 100%; height: auto; } // push image block images to full width if needed
// image effects (must be assigned direct to img element)
img.corner0, .corner0 img, img.photo, .photo img { border-radius: 0; }
img.corner8, .corner8 img { border-radius: 8px; }
img.img-circle, .img-circle img { border-radius: 50%; }
img.tilt-r, .tilt-r img { transform: rotate(2deg); }
img.tilt-l, .tilt-l img { transform: rotate(-2deg); }
img.keyline, .keyline img, img.key, .key img { border: #aaa solid 1px; }
img.outline, .outline img { outline: #999 solid 1px; } // outlines take up no space, but cannot have a radius and must be all-round
img.drop, .drop img { box-shadow: $picture-shadow; }
img.clear-md-tb, .clear-md-tb img { margin: 20px 0 30px; }
img.clear-sm-tb, .clear-sm-tb img { margin: 10px 0 15px; }
img.photo, .photo img { padding: 2%; box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.3); background: #fff; border: #ddd solid 1px; margin: 10px; }
// remove margin for image blocks
img.ccm-image-block.photo, .photo img.ccm-image-block { margin: 0; }
// standard positions/sizes
.full-100, .left-70, .right-70, .mid-70, .left-50, .right-50, .mid-50, .left-30, .right-30, .mid-30, .left-15, .right-15, .mid-15 { margin: 0; color: red; }
img.full-100, .full-100 img { width: 100%; margin: 10px 0 10px 0; }
.mid-70 img, .mid-50 img, .mid-30 img, .mid-15 img { text-align: center; margin: 5px; }
img.left-70, .left-70 img, img.left-50, .left-50 img, img.left-30, .left-30 img, img.left-15, .left-15 img { float: left; margin: 10px 25px 10px 0; }
img.left-15, .left-15 img { margin-right: 15px; }
img.right-70, .right-70 img, img.right-50, .right-50 img, img.right-30, .right-30 img, img.right-15, .right-15 img { float: right; margin: 10px 0 10px 10px; }
.right picture img, .right img { float: right; }
// change width proportions according to viewport size
img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img { width: 70%; }
img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img { width: 50%; }
img.mid-30 { margin: 5px 15%; }
img.mid-15 { margin: 5px 25%; }
@include media-breakpoint-up(sm) {
    img.mid-50, .mid-50 img, img.left-50, .left-50 img, img.right-50, .right-50 img { width: 70%; }
    img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img { width: 50%; }
    img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img { width: 30%; }
    img.mid-50 { margin: 5px 15%; }
    img.mid-30 { margin: 5px 25%; }
    img.mid-15 { margin: 5px 35%; }
}
@include media-breakpoint-up(md) {
    img.mid-70, .mid-70 img, img.left-70, .left-70 img, img.right-70, .right-70 img { width: 70%; }
    img.mid-50, .mid-50 img, img.left-50, .left-50 img, img.right-50, .right-50 img { width: 50%; }
    img.mid-30, .mid-30 img, img.left-30, .left-30 img, img.right-30, .right-30 img { width: 30%; }
    img.mid-15, .mid-15 img, img.left-15, .left-15 img, img.right-15, .right-15 img { width: 15%; }
    img.mid-70 { margin: 5px 15%; }
    img.mid-50 { margin: 5px 25%; }
    img.mid-30 { margin: 5px 35%; }
    img.mid-15 { margin: 5px 42%; }
}


// -------------------------------
//  PLAIN IMAGE OVERLAY (three versions)
//  class goes on a parent div to an anchor, which wraps the picture
// -------------------------------
.plus-on-hover, .arrows-on-hover, .search-on-hover { position: relative; }
.plus-on-hover a picture::before,
.arrows-on-hover a picture::before,
.plus-on-hover a picture::before {
    display: flex; justify-content: center; align-items: center;
    position: absolute; top: 0; bottom: 0; left: 0; right: 0; 
    content: "\f055"; font-family: "Font Awesome 5 Free"; font-size: 50px; color: #fff;
    font-style: normal; font-variant: normal; text-rendering: auto; font-weight: 900;
    background-color: rgba(0,0,0,0.3); opacity: 0; transition: opacity 0.5s;
}
.arrows-on-hover a picture::before { content: "\f31e"; } // arrows
.search-on-hover a picture::before { content: "\f00e"; } // search
.plus-on-hover a:hover picture::before,
.arrows-on-hover a:hover picture::before,
.search-on-hover a:hover picture::before { opacity: 1.0; transition: opacity 0.5s; }
.plus-on-hover img { margin: 0 auto; } // centre image when samller than container


// -------------------------------
//  IMAGE HOVER OVERLAY (works with JS in main.js)
// -------------------------------
// because in-para images float, we have to use JS to create the hover effect (see main.js)
a img.img-show, a img.img-get, a img.img-link { filter: brightness(100%); transition: 0.5s; }
a:hover img.img-show, a:hover img.img-get, a:hover img.img-link { filter: brightness(50%); transition: 0.5s; }
a .img-caption { opacity: 0; z-index: 1; text-align: center; transition: 0.5s; }
a .img-caption i { font-size: 40px; color: #fff; }
a .img-caption p { font-size: $font-size-base-px; color: #fff; margin: 5px 0; }
a:hover .img-caption { opacity: 1; transition: 0.5s; }


// -------------------------------
//  GALLERY FILTERS
// -------------------------------
ul.filters { list-style: none; margin: 25px 0 0; text-align: center; }
ul.filters li { display: inline-block; }
ul.filters li a { display: block; padding: 3px 8px; border: 1px transparent solid; color: inherit; cursor: pointer; border-radius: 3px; transition: background-color 0.2s; }
ul.filters li.active a { border: 1px #555 solid; } //background-color: #fcf9d9; }
ul.filters li a:hover { text-decoration: none; background-color: #555 !important; color: #fff !important; }
ul.filters.filters-openup li { margin-right: 10px; }
.filter-container { margin-top: 15px; }
.filtr-item { padding: 5px 15px 20px; }


// -------------------------------
// THEME STYLES (INSET BOXES)
// -------------------------------
// for Main Content area, Sidebar area, or all blocks:
.grey-bg, .colour1-bg, .colour2-bg, .colour3-bg, .success-bg, .highlight-bg, .warning-bg, .danger-bg, .black-bg { background: #f3f3f3; padding: 20px; border-radius: $border-radius-lg; margin-bottom: 20px; }
.colour1-bg { background: $brand1-very-light; }
.colour2-bg { background: $brand2-very-light; }
.colour3-bg { background: $brand3-very-light; }
.success-bg { background: mix($white, $success, 80%); }
.highlight-bg { background: #ffa; }
.warning-bg { background: mix($white, $warning, 70%); }
.danger-bg { background: mix($white, $danger, 70%); }
.grey-bg p:last-child, .colour1-bg p:last-child, .colour2-bg p:last-child, .colour3-bg p:last-child, 
    .success-bg p:last-child, .highlight-bg p:last-child, .warning-bg p:last-child, .danger-bg p:last-child, .black-bg p:last-child { margin-bottom: 5px; }
.black-bg { background: #000; color: #ccc; }
.black-bg h1, .black-bg h2, .black-bg h3, .black-bg h4 { color: #ddd; }
.black-bg b, .black-bg strong { color: #fff; }
.black-bg a:not(.btn) { color: #fc9c9c; }

// -------------------------------
// MAIN NAVIGATION MENU
// -------------------------------
// make navbar scrollable for phones
.navbar-ss6 .navbar-nav { overflow-y: scroll; max-height: calc(100vh - 57px); }
// remove/re-set concrete styling
.navbar-ss6 .dropdown-item, .navbar-ss6 .dropdown-item:hover, .navbar-ss6 .dropdown-item:active { background-color: inherit; }
.navbar-ss6 .navbar-nav li, .navbar-ss6 .navbar-nav li { position: relative; } // not needed but keep all items consistent
.navbar-ss6 .navbar-nav li, .navbar-ss6 .navbar-nav li > a { font-size: $font-size-header-sm; font-weight: $body-font-weight; text-decoration: none; }

// top navbar colour and height for phones
.top .navbar  { background: $tarmac-tile-dark; height: $navbar-height-phone; margin-top: $space-above-nav-phone; border-bottom: 3px solid #000; }
.top .navbar .navbar-brand-small { height: $navbar-height-phone; }

// animated toggler button for phones
.navbar-toggler .icon-bar { display: block; width: 33px; height: 2px; transition: all 0.2s; background-color: $toggler-bars; }
.navbar-toggler .icon-bar:nth-of-type(1) { transform: rotate(45deg); transform-origin: 10% 10%; }
.navbar-toggler .icon-bar:nth-of-type(2) { opacity: 0; }
.navbar-toggler .icon-bar:nth-of-type(3) { transform: rotate(-45deg); transform-origin: 10% 90%; }
.navbar-toggler.collapsed .icon-bar:nth-of-type(1) { transform: rotate(0); }
.navbar-toggler.collapsed .icon-bar:nth-of-type(2) { opacity: 1; }
.navbar-toggler.collapsed .icon-bar:nth-of-type(3) { transform: rotate(0); }
.navbar-toggler .icon-bar + .icon-bar { margin-top: 7px; }
.navbar-toggler { border: 0; }
.navbar-toggler:focus { box-shadow: 0 0 0 0; }

// navbar branding for phones (using the small logo)
.navbar-brand-small img.logo { display: inline-block; max-height: 36px; margin-top: $space-above-logo-phone; }
.navbar-ss6 > .container-md { padding: 0 $space-beside-logo-phone; position: relative; height: 100%; align-items: center; transition: all 0.8s; } // note that align-items changes above phones
// move large logo off screen for phones but set it ready for tablets
.navbar-brand { @extend %logo-alignment; opacity: 0; position: absolute; top: -100px; height: ($space-above-nav-tablet - ($space-above-logo-tablet + ($space-above-logo-tablet * $space-ratio-below-logo))); transform: translateY($space-above-logo-tablet); padding: 0; margin: 0 15px; z-index: -1; transition: all 0.8s; transition-delay: 0.2s; } // show the main logo slowly (transition down)
.navbar-brand img { height: 100%; display: block; margin: 0 auto; filter: drop-shadow(10px 5px 5px #000); }

// tinting for selected nav links
@include media-breakpoint-down(md) {
    .navbar-ss6 .navbar-nav li > a:focus,
    .navbar-ss6 .navbar-nav li > a:hover,
    .navbar-ss6 .navbar-nav li.active > a,
    .navbar-ss6 .navbar-nav li.active > a:focus,
    .navbar-ss6 .navbar-nav li.active > a:hover,
    .navbar-ss6 .navbar-nav .open .dropdown-menu > .active > a:focus,
    .navbar-ss6 .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-ss6 .navbar-nav li.open > a,
    .navbar-ss6 .navbar-nav li.open > a:focus,
    .navbar-ss6 .navbar-nav li.open > a:hover { background-color: rgba(0,0,0, 0.15); }
}

// first-level nav styling
.navbar-ss6 .navbar-nav span.fa-home { opacity: 1; }
.navbar-collapse { background-color: $nav-drop-lev1; padding: 0; margin: 0 -12px; } // colour of first-level drop-down menu on phones (only)
.navbar-ss6 .navbar-nav li.nav-item { padding: 0; } // padding for first-level menu (make zero)
.navbar-ss6 .navbar-nav li.nav-item > a.nav-link { color: $nav-text-lev1; padding: 8px 10px 5px 15px; filter: drop-shadow(1px 1px 2px #000); } // text for first-level menu
// second-level nav styling
.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu.show { background-color: $nav-drop-lev2; padding: 0; margin: 0 15px; border-color: #000; } // second-level menu on phones (only)
.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item { padding: 0; } // padding for second-level menu (make zero)
.navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { color: $nav-text-lev2; font-weight: $headings-font-weight; text-shadow: $text-shadow; padding: 5px 10px 5px 15px; } // second-level text on phones
// third-level nav styling
.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu.show { background-color: $nav-drop-lev3; padding: 0; margin: 0 15px; border-color: #000; } // third-level menu on phones (only)
.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item { padding: 0; } // padding for third-level menu (make zero)
.navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { color: $nav-text-lev3; font-weight: $headings-font-weight; text-shadow: $text-shadow; padding: 3px 10px 3px 12px; } // third-level text on phones
// dropdown carets/arrows (down)
.navbar-ss6 .navbar-nav a.dropdown-toggle:after { vertical-align: 1px; margin-left: 5px; border-left: 8px solid transparent; border-right: 8px solid transparent; border-top: 8px solid #fff;}
.navbar-ss6 .navbar-nav li.search-item a.dropdown-toggle:after { display: none; }
// active (current page or path) nav items
//.navbar-ss6 .navbar-nav > li.active a.nav-link.active { background-color: rgba(255,255,255,0.15); border: 1px rgba(255,255,255,0.25) solid; border-left: none; border-right: none; } // first-level
.navbar-ss6 .navbar-nav > li.active .dropdown-menu > li.active > a.nav-link.active { background-color: rgba(255,255,255,0.3); border: 1px rgba(255,255,255,0.4) solid; border-left: none; border-right: none; } // second-level
// sub-menu section header (external link with #)
.navbar-ss6 .navbar-nav li.dropdown-item .nav-intro { color: $black; font-weight: $headings-font-weight; padding: 3px 0 3px 12px; background: rgba(255,255,255,0.15); border-top: 3px solid rgba(0,0,0,0.3); border-bottom: 3px solid rgba(255,255,255,0.2); white-space: nowrap; }
// menu search item
.navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show { padding: 10px 15px; margin: 0; background-color: $nav-drop-lev2; }
.navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show > li.dropdown-item { padding: 0; margin: 0; border: 1px solid #333; }

// sub-menu positioning on TABLETS and above
@include media-breakpoint-up(md) {

    // disable scrollable menus for phones else menus are hidden
    .navbar-ss6 .navbar-nav { overflow: visible; max-height: none; }
    // we wanted the toggler in the centre, but the nav menu needs to sit at the bottom of the container
    .navbar-ss6 > .container-md { align-items: flex-end; }
    // top navbar colour and height for tablets
    .top .navbar.navbar-ss6 { height: $navbar-height-normal+$space-above-nav-tablet; align-items: flex-end; background: $tarmac-tile-dark; transition: all 0.5s; } // transition down
    // alter styling when scrolled down the page
    .top .navbar.navbar-ss6.scroll { height: $navbar-height-normal+$space-above-nav-scrolled; box-shadow: $header-shadow-scrolled; transition: all 0.5s; } // transition up
    .top .navbar.navbar-ss6.scroll .navbar-brand { opacity: 0; top: -200px; transition: all 0.7s; transition-delay: 0; } // hide the main logo quickly (transition up)
    .navbar-ss6.scroll .navbar-nav > li > a:hover,
    .navbar-ss6.scroll .navbar-nav > li.active > a,
    .navbar-ss6.scroll .navbar-nav > li.active > a:hover { background-color: rgba(0,0,0, 0.15); }
    // active (current page or path) nav items
    //.navbar-ss6 .navbar-nav > li.active a.nav-link.active { border: none; }
    // move small logo off screen for tablets
    .navbar-brand-small { opacity: 0; position: absolute; top: 0; left: -999px; }
    // move large logo down onto screen for tablets
    .navbar-brand { opacity: 1; top: 0; }
    // align the top nav menu elements
    .navbar-ss6 .navbar-collapse { flex-direction: row; justify-content: $nav-menu-alignment; }
    // align dropdowns to right of each other
    .navbar.fixed-top .navbar-nav .dropdown-menu { left: auto; right: 0; }
    .navbar.fixed-top .navbar-nav .dropdown-menu .dropdown-menu { top: 0; left: 95%; right: auto; margin-top: 5px; }
    // if a menu is very long, a note is added to the menu with JS: "This list is scrollable"
    // note that this uses overflow:scroll; so the shortened menu cannot contain other dropdown menus
    // comment out if not required (also comment out the JS code)
    //li.li-note-up, li.li-note-down { margin: -5px 0 5px; background: #fbf5bf; padding: 10px 20px; color: $brand1-darker; white-space: nowrap; }
    //li.li-note-up { margin: 5px 0 -5px; }

    // top-level nav styling
    .navbar-collapse { background-color: transparent; margin: 0; } // colour of top-level menu on tablets
    .navbar-ss6 .navbar-nav li.nav-item > a.nav-link { color: $nav-text-lev1; padding: 15px 11px 11px; border: none; } // text for first-level menu
    .navbar-ss6 .navbar-nav li.nav-item.active > a.nav-link { color: #ffff33; }
    .navbar-ss6 .navbar-nav li.nav-item.active > a.nav-link > span.fa-home { color: $nav-text-lev1; }
    // top-level nav on the hover
    .navbar-ss6 .navbar-nav li.nav-item > a.nav-link:hover { color: #ff5d5d; }
    .navbar-ss6 .navbar-nav li.nav-item > a.nav-link > span.fa-home:hover { color: #ff5d5d; }
    // dropdown carets/arrows (down)
    .navbar-ss6 .navbar-nav a.dropdown-toggle:after { border-top-color: #999; }
    // first-level nav styling
    .navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu.show { background-color: $nav-drop-lev1; margin: 0; } // first-level menu on tablets
    .navbar-ss6 .navbar-nav > .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { color: $nav-text-lev1; padding: 7px 10px; } // first-level text on tablets
    // second-level nav styling
    .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu.show { background-color: darken($nav-drop-lev1, 3%); margin: 5px 0 0;} // second-level menu on tablets
    .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { color: $nav-text-lev1; padding: 5px 10px; } // second-level text on tablets
    // third-level nav styling
    .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu.show { background-color: $nav-drop-lev2; margin: 5px 0 0;} // second-level menu on tablets
    .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { color: $nav-text-lev2; padding: 5px 10px; } // second-level text on tablets
    // dropdown carets/arrows (down)
    .navbar-ss6 .navbar-nav a.dropdown-toggle:after { margin-left: 3px; border-width: 7px; }
    // dropdown carets/arrows (right)
    .navbar-ss6 .navbar-nav .dropdown-menu a.dropdown-toggle:after { vertical-align: -3px; margin-left: 8px; border-right: 0; border-bottom: 8px solid transparent; border-top: 8px solid transparent; border-left: 8px solid rgba(0,0,0, 0.4); }
    // menu search item
    .navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show > li.dropdown-item { border-color: darken($nav-drop-lev1, 20%); }
    .navbar-ss6 .navbar-nav > li.search-item > .search-wrapper > .dropdown-menu.show { width: 300px; padding: 15px; background-color: $nav-drop-lev1; border: 1px solid #333; box-shadow: $picture-shadow; }
    // animate the sub-menu openings (but not for phones as this clashes with BS5's styling)
    // note that BS5 adds and removes 'display: block;' with JS, so we have to override this, then show and hide the dropdown menu with 'visibility'.
    .navbar-ss6 .navbar-nav .dropdown .dropdown-menu { transform: scaleY(0); transition: transform 0.2s; transform-origin: left top; display: block !important; visibility: hidden; }
    .navbar-ss6 .navbar-nav .dropdown .dropdown-menu.show { transform: scaleY(1); transition: transform 0.5s; visibility: visible; } 
}

// sub-menu positioning on LAPTOPS and above
@include media-breakpoint-up(lg) {
    // top navbar colour and height for laptops
    .top .navbar.navbar-ss6 { height: $navbar-height-normal+$space-above-nav-medium; }
    // change logo position for laptops
    .navbar-brand { height: ($space-above-nav-medium - ($space-above-logo-medium + ($space-above-logo-medium * $space-ratio-below-logo))); transform: translateY($space-above-logo-medium); }
    // top-level nav styling
    .navbar-ss6 .navbar-nav li.nav-item > a.nav-link { font-size: $font-size-header-md; padding: 13px 15px 11px; }
    // second-level nav styling
    .navbar-ss6 .navbar-nav > .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { font-size: $font-size-dropdown-md; padding: 5px 15px; }
    // third-level nav styling
    .navbar-ss6 .navbar-nav > .dropdown .dropdown .dropdown > .dropdown-menu > .dropdown-item > a.nav-link { font-size: $font-size-dropdown-md; padding: 5px 13px; }
    .navbar-ss6 .navbar-nav li.dropdown-item .nav-intro { font-size: $font-size-dropdown-md; }
}

// sub-menu positioning on DESKTOPS and above
@include media-breakpoint-up(xl) {
    // top navbar colour and height for laptops
    .top .navbar.navbar-ss6 { height: $navbar-height-normal+$space-above-nav-large; }
    // change logo position for laptops
    .navbar-brand { height: ($space-above-nav-large - ($space-above-logo-large + ($space-above-logo-large * $space-ratio-below-logo))); transform: translateY($space-above-logo-large); }
    // top-level nav styling
    .navbar-ss6 .navbar-nav li.nav-item > a.nav-link { font-size: $font-size-header-lg; padding: 12px 15px 9px; }
}

// bring the small logo in and align the nav right when scrolling on desktops bigger than $min-width-show-sm-logo
// note that this section will need reconfiguring if the main nav menu is aligned left
@media (min-width: $min-width-show-sm-logo) {
    .navbar-ss6.scroll .navbar-brand-small { opacity: 1; left: $space-beside-logo-phone; transition-delay: 2s; transition: left 1s; }
    .navbar-ss6.scroll .navbar-collapse { justify-content: flex-end; } // 'flex-start' for aligned left
}

// pull the second-level sub-menu out left for the last menu item on anything smaller than a laptop
@include media-breakpoint-down(lg) { 
    .navbar-ss6 .navbar-nav > li:nth-last-child(2) .dropdown-menu > li .dropdown-menu { left: auto; right: 97%; }
    .navbar-ss6 .navbar-nav > li:nth-last-child(2) .dropdown-menu > li .dropdown-menu > li .dropdown-menu { right: auto; left: 95%; }
}
// pull the second-level sub-menu out left if the top nav bar is on the right when scrolling on desktops bigger than $min-width-show-sm-logo
@media (min-width: $min-width-show-sm-logo) {
    .navbar-ss6.scroll .navbar-nav > li .dropdown-menu > li .dropdown-menu { left: auto; right: 97%; }
    .navbar-ss6.scroll .navbar-nav > li .dropdown-menu > li .dropdown-menu > li .dropdown-menu { right: auto; left: 95%; }
}


// -------------------------------
//  BREADCRUMBS
// -------------------------------
@include media-breakpoint-down(md) {
    .breadcrumbs-container { display: none; } // hide the breadcrumbs for phones
}
.breadcrumbs-container { opacity: 0.7; }
.breadcrumbs-container .breadcrumb { font-size: $font-size-small; color: #999; padding: 9px 0 7px; background: transparent; border-radius: 0; margin-bottom: 0; }
.breadcrumbs-container .breadcrumb li a { display: inline-block; color: #999; padding: 0; border-radius: 3px; transition: all 0.2s; }
.breadcrumbs-container .breadcrumb li a:hover { color: $white; text-decoration: none; background: rgba(0,0,0, 0.6); }
.breadcrumb > li + li:before { color: #bbb; content: "\232a"; padding: 0 0 0 8px; }
.breadcrumb > .active { color: #777; }


// -------------------------------
//  SIDE NAV
// -------------------------------
.side-nav { background: $tarmac-tile-light-red; } // side-nav background colour
.side-nav { border-radius: $border-radius; margin: 0 0 15px; } // side-nav wrap corners
.side-nav.list-group { padding-bottom: 10px; }
.side-nav.list-group + .side-nav.list-group { margin-top: 30px; } // side-nav followed by side-nav
.side-nav.list-group a:nth-child(2) { padding-top: 10px; }
.side-nav .list-group-item h3 { font-size: $h4-font-size; }
.side-nav .list-group-item h3, .side-nav .list-group-item h4 { margin: 0; }
.side-nav .list-group-item:first-child h3 { color: $brand1-dark; border: none; } // title colour
.side-nav .list-group-item:first-child { padding: 15px 15px 10px; background: $tarmac-tile-mid-red; border-bottom: 1px $brand1-dark solid; } // title box
.side-nav-parent .list-group-item:first-child { background: $brand3-light; border-bottom: 1px $brand3-dark solid; } // title box
.side-nav .list-group-item { font-size: $font-size-small + 1; } // nav items
.side-nav .list-group-item, .side-nav .list-group-item:focus { padding: 5px 15px; background: transparent; border: 0; color: #000; transition: all 0.5s; } // nav items
.side-nav .list-group-item.active, .side-nav .list-group-item.active:hover { color: $white; padding-left: 15px; background: $tarmac-tile-dark-red; } // active nav item
.side-nav a.list-group-item:hover { color: #000; text-decoration: none; padding-left: 25px; padding-right: 0; background-color: rgba(0,0,0, 0.1); } // nav item hover
// changes for parent and AONB side-nav
.side-nav-parent { background: $brand3-lighter; }
.side-nav-parent .list-group-item:first-child h3 { color: $brand3-dark; }
.side-nav-parent .list-group-item:first-child { border-bottom: 1px $brand3-dark solid; }
.side-nav-parent .list-group-item.active, .side-nav-parent .list-group-item.active:hover { background: $brand3; }
// menu headers
.list-group .list-group-item.nav-intro { padding: 5px 15px; background: rgba(0,0,0,0.1); color: $black; font-weight: $headings-font-weight; }
.list-group .list-group-item.nav-intro:hover { padding-left: 15px; }


// -------------------------------
//  TOP TABS NAV
// -------------------------------
div.extend-width.top-tabs-outer { margin-top: -40px; margin-bottom: 70px;
    background: rgb(187,187,187) linear-gradient(0deg, rgba(187,187,187,1) 0%, rgba(255,255,255,1) 100%); }
div.extend-width.top-tabs-outer > .container { border-bottom: 1px solid #888; }
ul.top-tabs-nav { justify-content: center; padding-top: 30px; }
ul.top-tabs-nav > li { margin-right: 4px; }
ul.top-tabs-nav > li > a { display: block; padding: 6px 8px; background: #fff; border-radius: 8px 8px 0 0; }
ul.top-tabs-nav > li > a:hover { background: #fffacd; text-decoration: none; }
ul.top-tabs-nav > li.active > a { border: 1px solid #888; border-bottom: none; margin-bottom: -1px; 
        color: $text-color; font-family: $font-family-header; font-weight: $headings-font-weight; }
ul.top-tabs-nav > li.active > a:hover { background: #fff; }
@include media-breakpoint-up(sm) { 
    ul.top-tabs-nav > li { margin-right: 6px; }
    ul.top-tabs-nav > li > a { padding: 6px 12px; }
}
@include media-breakpoint-up(md) { 
    div.extend-width.top-tabs-outer { margin-top: -30px; }
    ul.top-tabs-nav > li { margin-right: 8px; }
    ul.top-tabs-nav > li > a { padding: 6px 20px; }
}
@include media-breakpoint-up(lg) { 
    div.extend-width.top-tabs-outer { margin-top: -35px; }
}
@include media-breakpoint-up(xl) { 
    div.extend-width.top-tabs-outer { margin-top: -40px; }
}


// -------------------------------
//  PAGINATION
// -------------------------------
div .pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover,
div .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover { background-color: $brand1; border-color: $brand1; }
div .pagination > li > a { background-color: #eee; color: #000; border-color: #bbb; }
div .pagination > li > a:hover { background-color: #ee9; border-color: #cc6; color: #000; }
div .pagination > li.disabled > span { color: #bbb; }


// -------------------------------
//  BULLETS + ORDERED LISTS
// -------------------------------
.bullets ul { display: table; overflow: hidden; list-style: none; margin-top: -8px; padding: 0; }
.bullets h2 + ul, .bullets h2 + ol { margin-top: 0; }
.bullets ul li { padding-left: 1.3em; margin-bottom: 3px; position: relative; }
.bullets ul li:before { content: "\2022 \020"; font-size: 3.2em; color: $brand2; position: absolute; top: -.53em; left: 0; }
.bullets ol { display: table; overflow: hidden; list-style: none; margin-top: 0; margin-left: 0; padding: 0; counter-reset: my-counter; }
//.bullets p + ol { margin-top: -10px; }
//.bullets p + ol.small { margin-top: -15px; }
.bullets ol > li { padding-left: 1.8em; margin-bottom: 5px; position: relative; }
.bullets ol > li:before { content: counter(my-counter); counter-increment: my-counter; font: bold 19px/1.4 $font-family-header; 
    color: $brand1; position: absolute; top: 0.05em; left: 0; }
.bullets ul > li > ul > li:first-child, 
.bullets ul > li > ol > li:first-child, 
.bullets ol > li > ul > li:first-child, 
.bullets ol > li > ol > li:first-child { margin-top: 10px; }
.bullets ul > li > ul > li, .bullets ol > li > ul > li { font-size: 90%; margin-bottom: 2px; }
@media (min-width: $grid-breakpoint-xxs) { 
    .bullets ul li { padding-left: 1.5em; margin-bottom: 5px; }
}


// -------------------------------
//  ACCORDION
// -------------------------------
// style opening and closing blocks in edit mode
.acceditmode { padding: 5px 15px; background: #c49dec; color: #68438f; border: 3px solid #68438f; margin: 8px 0; }
.accOpen { border-bottom: none; border-radius: 8px 8px 0 0; }
.accClose { border-top: none; border-radius: 0 0 8px 8px; }
// normal operation
.accordion-item { margin-bottom: 5px; }
.accordion-item > h2, 
.accordion-item > h3, 
.accordion-item > h4, 
.accordion-item > p, 
.accordion-item > span { margin: 0; }
.roy-acc-block .accordion-body { background: #f0f0f0; color: $text-color; padding-top: 25px; padding-bottom: 10px; }
.accordion-item button.accordion-button { font-size: $h4-font-size * $font-size-reduction-phones; color: #fff; padding: 10px 15px; transition: all 0.5s; opacity: 1; box-shadow: none; }
button.accordion-button.grey { color: #444; background: #ccc; }
button.accordion-button.grey.collapsed { background: #ddd; }
button.accordion-button.brand1  { text-shadow: 1px 1px 1px rgba(0,0,0, 0.5); background: darken($brand1, 5%); }
button.accordion-button.brand1.collapsed { background: $brand1; opacity: 0.7; }
button.accordion-button.brand2  { text-shadow: 1px 1px 1px rgba(0,0,0, 0.5); background: darken($brand2, 15%); }
button.accordion-button.brand2.collapsed { background: $brand2; opacity: 1; }
.accordion-item button.accordion-button.brand2 { color: $brand2-darker; text-shadow: none; }
button.accordion-button.brand3  { text-shadow: 1px 1px 1px rgba(0,0,0, 0.5); background: darken($brand3, 15%); }
button.accordion-button.brand3.collapsed { background: $brand3; opacity: 1; }
// change the arrow colour
.accordion.roy-acc-block .brand1,
.accordion.roy-acc-block .brand2,
.accordion.roy-acc-block .brand3 {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
@include media-breakpoint-up(sm) { 
    .accordion-item button.accordion-button { font-size: $h4-font-size * $font-size-reduction-phones; }
}
@include media-breakpoint-up(md) { 
    .accordion-item button.accordion-button { font-size: $h4-font-size * $font-size-reduction-tablets; }
}
// concrete cms accordion block
.ccm-block-accordion button.accordion-button { background-color: #ddd; color: $headings-color; }


// -------------------------------
//  BLOG PAGE + CONVERSATIONS
// -------------------------------
.blog-title { margin-bottom: 8px; }
.blog-header-img { width: 100%; border: #999 solid 1px; margin: 0 0 20px 0; box-shadow: $picture-shadow; }
.poster { margin: -5px 0 8px; color: #999; }
h3.ccm-conversation-message-count { margin: 40px 0 20px; } // number of comments title
.ccm-block-next-previous h5 { margin-bottom: 0; }
h3.leave-message { padding: 15px 0; border-top: 10px solid #ddd; margin: 30px 0 10px; } // keyline over blog form
.ccm-conversation-wrapper input { font-size: 12px !important; } // jquery-ui override for inputs
.ccm-conversation-attachment-toggle { float: none !important; }
div.ccm-conversation-message.message { padding-top: 13px; border-top: 3px solid #e9e9e9; } // keyline over all conversations
div.ccm-conversation-message.message.ccm-conversation-message-level0 { border-top: 5px solid $brand1-lighter; } // keyline over conversation groups
div.ccm-conversation-message div.ccm-conversation-message-controls ul a.btn { padding: 3px 10px; margin-top: 10px; } // message reply button
div.ccm-conversation-message div.ccm-conversation-message-controls ul a.ccm-conversation-message-control-icon { padding: 0; } // message reply icons
div.ccm-conversation-messages-threaded div.ccm-conversation-message-level5 { margin-left: 200px; } // threaded message indent
div.ccm-conversation-messages-threaded div.ccm-conversation-message-level6 { margin-left: 200px; }
div.ccm-conversation-message div.ccm-conversation-avatar { top: 18px; } // avatar offset
.ccm-conversation-message-username { color: $brand1 !important; } // poster's name
.ccm-conversation-load-more-messages button { background: $warning; color: #fff; font-weight: $headings-font-weight; } // button to load more messages
.ccm-conversation-load-more-messages button:hover { background: darken($warning, 10%); color: #fff; } // button to load more messages
.ccm-conversation-message-controls .btn-sm:hover { opacity: 1; } // button to reply to a post
.ccm-conversation-no-messages { display: none; } // hide "no messages posted" block
@include media-breakpoint-up(sm) { 
    .ccm-conversation-wrapper input { padding: 6px 12px !important; font-size: 17px !important; } // jquery-ui override for inputs
}
@include media-breakpoint-up(md) { 
    .ccm-block-next-previous { margin-top: -8px; }
    .blog-header-img { margin: 5px 0 30px 0; }
}
@include media-breakpoint-up(lg) { 
    .blog-header-img { margin: 5px 0 40px 0; }
}
/* //piano blog table
table.piano-table th, table.piano-table td { padding: 2px 5px; white-space: nowrap; }
table.piano-table th { font-weight: $body-font-weight; font-style: italic; background-color: #eee; padding: 5px; }
table.piano-table tr { border-bottom: 1px solid #ddd; }
table.piano-table .sound-group { background-color: #d1e0ed; font-weight: $headings-font-weight; font-size: 19px; color: #02437b; padding: 5px 10px; }
table.piano-table td:nth-child(3n) { font-weight: $headings-font-weight; color: #2672b2; }
*/

// -------------------------------
//  FORMS
// -------------------------------
.grey-bg > .inset-form, .colour1-bg > .inset-form, .colour2-bg > .inset-form, .colour3-bg > .inset-form,
.success-bg > .inset-form, .warning-bg > .inset-form, .danger-bg > .inset-form, .highlight-bg > .inset-form { margin: -20px; }
.inset-form { padding: 20px 10px; }
.inset-form h3 { margin-top: 0; }
.form-group { margin-bottom: 15px; }
@include media-breakpoint-up(md) {
    //.form-label { text-align: right; }
    .ccm-ui .form-label { text-align: left; }
}
.ccm-dashboard-express-form .text-muted.small, .miniSurveyView .text-muted.small { color: #c88 !important; opacity: 0.7; font-weight: $body-font-weight; padding-left: 5px; } // 'required' text
.margin-top-0 .g-recaptcha.ecRecaptcha { margin-bottom: 0; } // reduce space on narrow forms
.ccm-dashboard-express-form div.form-group { margin-bottom: 10px; }
// form placeholder text
input::placeholder, input.form-control::placeholder { color: #bbb !important; font: $body-font-weight $font-size-base $font-family-base; }
// Google Recaptcha box
.col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha { margin: 15px; }
@include media-breakpoint-up(md) { 
    .col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha { margin: 5px 0 15px 176px; }
}
@include media-breakpoint-up(lg) { 
    .col-md-9.col-content .g-recaptcha, .col-lg-9.col-content .g-recaptcha { margin: 5px 0 15px 233px; }
}
.grecaptcha-badge { z-index: 1; }
// hide 'Greenwich Mean Time (UTC)' text
.ccm-dashboard-express-form > fieldset > .mb-3 > .text-muted > small { display: none; }


// -------------------------------
//  SOCIAL LINKS
// -------------------------------
footer .social { font-size: 30px; text-align: left; padding: 0; }
footer .social li { display: inline; list-style-type: none; padding: 0 5px; }
footer .social a:hover { text-decoration: none; text-shadow: 0 0 5px #fff; }
.social-links.ccm-block-social-links svg { height: 24px; width: 24px; max-height: 24px; max-width: 24px; margin-bottom: 5px; } // for Twitter (X)
.share-icons #icon-twitter-x { color: #bbb; height: 20px; width: 20px; max-height: 20px; max-width: 20px; margin-bottom: 5px; } // share icons bottom of page
.share-icons #icon-twitter-x:hover { color: $link-hover-color; }
@include media-breakpoint-up(sm) { 
    footer .social { text-align: right; }
}


// -------------------------------
//  MANUAL NAV
// -------------------------------
.tight-nav { list-style: none; padding: 0; }
.tight-nav li { padding: 12px 0 12px 0; border-top: 1px #aaa solid; } // increase touch-target for phones
.tight-nav li a { padding-left: 0px; border-left: 0px $brand2 solid; transition: all 0.5s ease; }
.tight-nav li a:hover { text-decoration: none; padding-left: 10px; border-left: 10px $brand2 solid; }
.tight-nav li.active a { color: $white; padding: 2px 5px; border-left: none; background: rgba(0,0,0,0.2); }
.tight-nav li a.active:hover { padding-left: 0; border-left: 0; }
footer .tight-nav li a, footer .tight-nav li a:hover { border-color: $footer-hover; }
@include media-breakpoint-up(sm) { 
    .tight-nav li { padding: 0 0 3px 0; border-top: none; } // decrease touch-target for non-phones
}


// -------------------------------
//  IMAGE GALLERY VIEWER (SS6 MODS)
// -------------------------------
.mfp-image-holder .mfp-close { font-size: 50px; opacity: 1; } // close 'X'
.mfp-content .mfp-bottom-bar { padding: 10px 20px; margin-top: -25px; background-color: #666; min-height: 38px; }
.mfp-bottom-bar .mfp-title { text-align: center; color: #fff; }
.mfp-bottom-bar .mfp-title .ms-2 { margin-left: 20px !important; padding: 5px 10px; background-color: #aaa; }
.mfp-bottom-bar .mfp-counter { right: 12px; top: 10px; }


// -------------------------------
//  FEATURE (default and site-system template)
// -------------------------------
.ig-parent { position: relative; border: 1px solid transparent; }
.ig-parent .ig-overlay { z-index: 1; opacity: 0; position: absolute; top: 0; bottom: 0; 
    background-color: rgba(0,0,0,0.4); transition: opacity 0.5s; left: 0; right: 0; }
    // left: calc(var(--bs-gutter-x)*.5); right: calc(var(--bs-gutter-x)*.5); } // this compensates for the calculated gutter widths
.ig-parent:hover .ig-overlay { opacity: 1.0; }
.ig-image { text-align: center; } // for phones
.ig-overlay-text { display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; 
    font-size: $font-size-large; color: #fff; text-shadow: 2px 2px 6px rgba(0,0,0,0.6); }
.ig-overlay-text p { margin: 5px 0; } // downlaod text
.col-content div.ccm-block-feature-item, div.ccm-block-feature-link { margin: 20px 0; } // default blocks
.feature { display: block; margin-top: 20px; }
div.ccm-block-feature-item p,
div.ccm-block-feature-item h4,
div.ccm-block-feature-item h3,
div.ccm-block-feature-item h2 { margin-left: 30px; margin-top: 0; }
div.ccm-block-feature-link h4,
div.ccm-block-feature-link h3,
div.ccm-block-feature-link h2 { margin-top: 0; }
.feature img { float: left; margin: 5px 20px 5px 0; }
.feature .ig-overlay { margin: -10px -10px 0 -10px; background-color: rgba(0,0,0,0.3); }
a.feature, a.feature strong, a.feature b { color: $text-color; }
a.feature:hover { color: $text-color; text-decoration: none; }
.feature .feature-text p { margin-bottom: 10px; }
.feature .feature-icon { position: absolute; bottom: 0; right: 20px; font-size: 110px; color: rgba(0,0,0,0.1); z-index: 1; }


// -------------------------------
//  FEATURE SCROLL
// -------------------------------
.feature-scroll .feature-link .overlay { margin: -10px -10px 0 -10px; background-color: rgba(0,0,0,0.3); }
.feature-scroll .feature-link:hover { color: inherit; text-decoration: none; }
.feature-scroll .feature-link:hover .overlay { opacity: 1.0; }
.feature-scroll { position: relative; min-height: 120px; margin: 10px 0 15px; z-index: 2; border-bottom: 1px solid #eee; padding-bottom: 15px; }
.feature-scroll .overlay { z-index: 1; opacity: 0; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0,0,0,0.3); text-align: center; color: #fff; transition: opacity 0.5s; } // border-radius: $border-radius-large; }
section:not(.home-mainbody) .feature-link .feature-scroll .overlay { margin: -10px -10px 10px; }
.feature-scroll .overlay:before { content: ''; display: inline-block; width: 0; height: 100%; vertical-align: middle; }
.feature-scroll .overlay .overlay-name { display: inline-block; text-align: center; vertical-align: middle; transform: scale(0.3, 0.3); transition: transform 1s; width: 90%; }
.feature-scroll .feature-link:hover .overlay .overlay-name { transform: scale(1, 1); }
.feature-scroll .overlay-name i { color: #fff; font-size: 50px; text-shadow: 2px 2px 6px rgba(0,0,0,0.6); } // plus icon
.feature-scroll .overlay-name p { margin: 0; }
.feature-scroll .img-container { float: left; margin: 3px 20px 5px 0; text-align: center; }
.feature-scroll .img-container img { margin-bottom: 5px; } // increase bottom gap for phones
@include media-breakpoint-up(sm) {
    .feature-scroll .img-container img { margin-bottom: 0; }
}
.feature-scroll .img-container.pull-right { float: right; margin: 3px 0 5px 20px; }
.feature-scroll h4 { color: $headings-color; margin: 4px 0 7px; }
.feature-scroll p { color: $text-color; text-decoration: none !important; z-index: 2; margin: 0; }
.features-intro-line { font-style: italic; color: #999; margin: -10px 0 15px; }
@include media-breakpoint-up(md) { 
    .home-mainbody .feature-scroll { padding-right: 30px; }
    .features-intro-line { margin: -10px 0 25px; }
}
// red flag that shows an internal link
.feature-scroll.link-flag .img-container { position: relative; }
.feature-scroll.link-flag .img-container:before, .feature-scroll.link-flag .img-container:after { 
    content: "\f0c8"; font-family: "Font Awesome 5 Free"; font-size: $font-size-large; color: #fff;
    font-style: normal; font-variant: normal; text-rendering: auto; font-weight: 900; position: absolute; top: -4px; left: 1px; }
.feature-scroll.link-flag .img-container:after { content: "\f0fe"; color: #f00; }
.home-mainbody .feature-scroll.link-flag .img-container:before, .home-mainbody .feature-scroll.link-flag .img-container:after { content: none; }

// change layout for larger images
.feature-scroll-450 .img-container img { border: 1px solid #777; border-bottom: none; }
.feature-scroll-450 .img-container { position: relative; }
.feature-scroll-450 .img-container:after { position: absolute; left: 0; right: 0; top: 0; bottom: 0; content: ' '; 
    display: block; background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0)) bottom no-repeat; background-size: 100% 10%;
}
.feature-scroll-450 .img-container,
.feature-scroll-550 .img-container { margin: 3px 35px 5px 0;}
.feature-scroll-450 .img-container.pull-right,
.feature-scroll-550 .img-container.pull-right { margin: 3px 0 5px 35px; }
@include media-breakpoint-up(lg) {
    .feature-scroll-450 h4, .feature-scroll-550 h4 { margin-top: 25px; }
    .feature-scroll-450, .feature-scroll-550 { padding-bottom: 25px; }
}
@include media-breakpoint-down(lg) {
    .feature-scroll.feature-scroll-450 .img-container { float: none; margin: 3px auto 5px; }
}
@include media-breakpoint-down(xl) {
    .feature-scroll.feature-scroll-550 .img-container { float: none; margin: 3px auto 5px; }
}
@include media-breakpoint-down(lg) {
    .feature-scroll.feature-scroll-450 .img-container,
    .feature-scroll.feature-scroll-550 .img-container img { margin-bottom: 15px; }
}


// -------------------------------
//  SCROLL GALLERY
// -------------------------------
.scroll-gallery { margin-bottom: 20px; }
.scroll-gallery h4, .scroll-gallery h3 { margin-top: 0; }
.scroll-gallery .scroll-gallery-item { margin-top: -10px; }
@include media-breakpoint-up(sm) {
    .scroll-gallery .col-sm-6 > h4, .scroll-gallery .col-sm-6 > h3 { margin-top: -5px; }
    .scroll-gallery .col-sm-12 > h4 .scroll-gallery .col-sm-12 > h3 { margin-top: 0; }
}
@include media-breakpoint-up(md) {
    .scroll-gallery h4, .scroll-gallery h3 { margin-top: 0; }
    .scroll-gallery .col-sm-12 > h4, .scroll-gallery .col-sm-12:not(.col-md-12) > h3 { margin-top: -5px; }
}
@include media-breakpoint-up(xl) {
    .col-content .scroll-gallery .scroll-gallery-item { margin-top: 15px; }
    .col-content .scroll-gallery { margin-bottom: 30px; }
}
@include media-breakpoint-up(xxl) {
    .col-content .scroll-gallery .scroll-gallery-item { margin-top: 20px; }
    .col-content .scroll-gallery { margin-bottom: 40px; }
}


// -------------------------------
//  SITE MAP + SEARCH BOX (also have separate css files)
// -------------------------------
li.map1 { font-weight: $headings-font-weight; }
.search-box { background-color: $brand1; margin-bottom: 25px; }
.search-box button { background-color: $brand1-lighter; }


// -------------------------------
//  404 PAGE
// -------------------------------
.http-error { text-align: center; }
.http-error p { font-size: 18px; }
.http-error .http-error-number { font-size: 60px; line-height: 120%; font-family: $font-family-header; font-weight: $headings-font-weight; }
.http-error .icon-image { font-size: 70px; }
@include media-breakpoint-up(sm) {
    .http-error { margin-bottom: 50px; } 
    .http-error p { font-size: 22px; }
    .http-error .http-error-number { font-size: 130px; margin-bottom: 20px; }
    .http-error .icon-image { font-size: 150px; }
}


// -------------------------------
//  MENU LISTING
// -------------------------------
/*
h3.tab-title small { display: block; font-size: $font-size-base; color: $brand1; line-height: $line-height-base; font-weight: $body-font-weight; margin-top: 10px; }
.tabs-brand2 h3.tab-title small { color: $brand2-darker; }
.tabs-brand1 .tab-title { padding: 15px 25px; background: $brand1-lighter; }
.tabs-brand2 .tab-title { padding: 15px 25px; background: $brand2-lighter; }
.menu p { padding: 0 15px 15px; margin: 0; border-bottom: 1px solid #ddd; }
p.menu-title { color: red; }
.menu .menu-title { color: $black; font-size:$font-size-large; font-weight: $headings-font-weight; padding: 15px 15px 0; border-bottom: none; }
.menu .menu-title strong { float: right; font-weight: $body-font-weight; font-size: $font-size-large; color: #888; }
.menu .menu-note { color: $brand1; font-style: italic; padding: 15px; border-bottom: 1px solid #ddd; }
@include media-breakpoint-down(sm) { 
   .menu p, .menu .menu-title, .menu .menu-note { padding-left: 0; padding-right: 0; }
}*/


// -------------------------------
// NEWS + CLASSIFIED + FORUM
// -------------------------------
.news h3.ccm-conversation-message-count, .classified h3.ccm-conversation-message-count { display: none; } // hide number of comments on classifieds page
.news .ccm-conversation-avatar, .classified .ccm-conversation-avatar { display: none; } // hide avatars on classifieds page
.news .message, .news .ccm-conversation-message, .classified .message, .classified .ccm-conversation-message { padding-left: 0; } // move content to fill above space
.news .subject-input, .classified .subject-input { display: block !important; } // show message title input
.news .ccm-conversation-message-username { margin: 5px 0; color: #921d34; border-left: 20px solid #c79ba4; padding-left: 10px; }
.classified .ccm-conversation-message-username { margin: 5px 0; color: #791d92; border-left: 20px solid #bcacc0; padding-left: 10px; }
.news .ccm-conversation-message-date, .classified .ccm-conversation-message-date { font-size: $font-size-small; font-style: italic; }
.news div.ccm-conversation-message .ccm-conversation-message-byline, 
.classified div.ccm-conversation-message .ccm-conversation-message-byline { padding-bottom: 3px; }
.forum .ccm-conversation-message div.ccm-conversation-message-byline span.ccm-conversation-message-username { color: #a78e17; }


// -------------------------------
// EVENTS CALENDAR
// -------------------------------
.ds-event-modal > .container>.header { background-color: desaturate($brand2, 30%); } // event modal header
.ds-event-modal>.container>.content .time { background-color: $brand2-lighter; color: $brand2-darker; } // event modal date/time
.fc-view.fc-month-view > table { background-color: #f5f9fb; } // diary table
.btn-diary { margin-top: -43px; } // button below diary on home page (move it up)


// -------------------------------
// EVENTS DATE ICON (CS2)
// -------------------------------
$icon-brand1: desaturate(lighten($brand1, 10%), 20%);
$icon-brand2: desaturate(lighten($brand2, 10%), 20%);
$icon-red: #c82c1a;
$icon-blue: #3f73a8;
$icon-gold: #c2a830;
$icon-green:#42ad35;
time.cal-icon { float: left; position: relative; width: 7em; height: 6.6em; font-size: 1em; background-color: #fff; margin: 0; 
    border-radius: 0.6em; box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd; overflow: hidden; }
time.cal-icon * { display: block; width: 100%; font-size: 1em; font-weight: $headings-font-weight; font-style: normal; text-align: center; }
time.cal-icon strong { position: absolute; top: 0; padding: 0.2em 0; color: #fff;
  border-bottom: 2px dashed rgba(0,0,0,0.3); border-radius: 0.6em 0.6em 0 0; }
time.cal-icon em { position: absolute; bottom: 0.2em; }
time.cal-icon span { width: 100%; font-size: 2.8em; letter-spacing: -0.05em; padding-top: 0.6em; color: #333; }
time.cal-icon-brand1 strong { background-color: $icon-brand1; box-shadow: 0 2px 0 $icon-brand1; }
time.cal-icon-brand1 em { color: $icon-brand1; }
time.cal-icon-brand2 strong { background-color: $icon-brand2; box-shadow: 0 2px 0 $icon-brand2; }
time.cal-icon-brand2 em { color: $icon-brand2; }
time.cal-icon-red strong { background-color: $icon-red; box-shadow: 0 2px 0 $icon-red; }
time.cal-icon-red em { color: $icon-red; }
time.cal-icon-blue strong { background-color: $icon-blue; box-shadow: 0 2px 0 $icon-blue; }
time.cal-icon-blue em { color: $icon-blue; }
time.cal-icon-gold strong { background-color: $icon-gold; box-shadow: 0 2px 0 $icon-gold; }
time.cal-icon-gold em { color: $icon-gold; }
time.cal-icon-green strong { background-color: $icon-green; box-shadow: 0 2px 0 $icon-green; }
time.cal-icon-green em { color: $icon-green; }


// -------------------------------
// ACTIVITY LISTS (CS2) and options
// -------------------------------
.activities.inset-1, .activities.inset-2, .activities.inset-3, .activities.inset-4, .activities.inset-5 { padding: 15px; border-radius: 2px; }
.activities.inset-1 { border: 1px #bbb solid; border-radius: 5px; } // keyline
.activities.inset-2 { background: #f3f3f3; } // grey
.activities.inset-3 { background: lighten($brand1-very-light, 3%); } // house colour
.activities.inset-4 { background: mix($white,#ffa, 60%); } // highlight (yellow)
.activities.inset-5 { background: mix($white, $danger, 85%); } // danger (red)
.activities.inset-0 .title, .activities.inset-1 .title, .activities.inset-2 .title, .activities.inset-3 .title, .activities.inset-4 .title, .activities.inset-5 .title { 
    display: block; text-align: center; padding: 15px 0 12px; margin: -15px -15px 0 -15px; background-color: rgba(0,0,0, 0.1); border-bottom: 2px dotted #bbb; border-radius: 2px 2px 0 0; 
}
.activities.inset-0 .title { text-align: left; padding-bottom: 20px; background-color: inherit; margin: 0; }
a#activity-details { display: block; position: relative; top: -50px; visibility: hidden; } // for phone jumps
.activities li h4 { font-style: normal; margin: 4px 0; }
.activities ul { list-style: none; padding: 0; margin: 0; }
.activities li { display: block; width: 100%; color: inherit; text-decoration: none; padding: 15px 0 12px 0; margin: 0; border-bottom: 2px dotted #bbb; }
.activities li.active { padding-left: 10px; background-color: #ffc; }
.activities .call-us, .activities .call-us b { text-align: center; padding: 7px 0; margin: 13px 0 10px; font-weight: $headings-font-weight; color: #390; border-top: 2px solid #390; border-bottom: 2px solid #390; }
.activities .fully-booked { text-align: center; padding: 7px 0; margin: 13px 0 10px; font-weight: $headings-font-weight; color: #df1e1e; border-top: 2px solid #df1e1e; border-bottom: 2px solid #df1e1e; }
.notes, .notes b { color: #888; }
.fa-grey { color: #aaa; margin-right: 7px; }
.text-grey { color: #999; }
.reserve-only, .reserve-only b, h4 .reserve-only { font-size: 0.8em; color: #c00; background: #fdfd6f; padding: 5px; }
.li-text { margin-left: 8.2em; }
.underway { color: #c00; background: #fdfd6f; padding: 5px 15px; line-height: 30px; } // banner if activity is underway
// edit box
.ccm-block-fields h4 { font-style: normal; }
h4 i { font-size: $font-size-small; font-style: normal; color: #aaa; }
// booking module
.booking dd { margin-bottom: 3px; }
.row > .control-label { padding-top: 7px; }

// -------------------------------
// STANDINGS TABLES (CS2)
// -------------------------------
.standings.inset-1, .standings.inset-2, .standings.inset-3, .standings.inset-4, .standings.inset-5, .standings.inset-6 { padding: 15px; border-radius: 2px; }
.standings.inset-1 { border: 1px #bbb solid; border-radius: 5px; } // keyline
.standings.inset-2 { background: #f3f3f3; } // grey
.standings.inset-3 { background: lighten($brand1-very-light, 3%); } // house colour
.standings.inset-4 { background: mix($white,#ffa, 60%); } // highlight (yellow)
.standings.inset-5 { background: mix($white, $danger, 85%); } // danger (red)
.standings.inset-6 { background: #f7f4df; } // gold
.standings.inset-0 .title, .standings.inset-1 .title, .standings.inset-2 .title, .standings.inset-3 .title, .standings.inset-4 .title, .standings.inset-5 .title, .standings.inset-6 .title { 
    display: block; text-align: left; padding: 15px 20px 12px; margin: -15px -15px 10px -15px; background-color: rgba(0,0,0, 0.1); border-bottom: 2px dotted #bbb; border-radius: 2px 2px 0 0; 
}
.standings.inset-0 .title { text-align: left; padding-bottom: 20px; background-color: inherit; margin: 0; }
table.all-cells-right th, table.all-cells-right td { text-align: right; }
.standings.inset-6 table thead { background: #efecd2; } // gold
.standings.inset-6 table.table-hover > tbody > tr:hover { background: #ece5bc; } // gold


// -------------------------------
// FOOD MENU
// -------------------------------
.food-menu { text-align: center; padding: 60px 35px; background-color: #faf9f5; border: solid 1px #bbb; box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.15); margin-top: 30px; }
.food-menu h2 { font-style: normal; background: rgba(0,0,0, 0.1); padding: 5px; margin: 50px 0 35px;}
.food-menu h3 { font-style: normal; margin-bottom: 10px; }
.food-menu h4 { font-style: normal; }
.food-menu p { margin-bottom: 5px; }


// -------------------------------
// TESTIMONIALS (colour only; rest is in block template)
// -------------------------------
div.ccm-block-testimonial-paragraph .fa-quote-left, div.ccm-block-testimonial-paragraph .fa-quote-right { color: $brand1-light; }


// -------------------------------
// BLOCKQUOTE
// -------------------------------
blockquote { border-left: 10px solid $brand1-light; background: lighten($brand1-very-light, 2%); }


// -------------------------------
// DIARY EVENT LIST
// -------------------------------
div div.ccm-block-calendar-event-list-event-date { border: 1px solid #333; border-radius: 3px; }
div div.ccm-block-calendar-event-list-event-date span:first-child { background-color: #900; }


// -------------------------------
//  NEWS CONVERSATIONS (not public submitable)
// -------------------------------
/*
.news .ccm-conversation-message-body ul { display: table; overflow: hidden; list-style: none; margin-top: -8px; padding: 0; }
.news .ccm-conversation-message-body ul li { padding-left: 1.3em; margin-bottom: 3px; position: relative; }
.news .ccm-conversation-message-body ul li:before { content: "\2022 \020"; font-size: 3em; color: $brand1; position: absolute; top: -.44em; left: 0; }
*/

// -------------------------------
//  COMMUNITY STORE (required globally)
// -------------------------------
div.store-whiteout { z-index: 1031; }
.help-block { display: block; margin-top: 5px; margin-bottom: 10px; color: #959595; }
/*
// utility links block
.store-utility-links { background: $cream; padding: 10px 15px; margin-bottom: 10px; }
.store-cart-empty .store-cart-link { display: none; }
.store-items { float: left; position: relative; }
.store-items .fa-shopping-basket { margin-left: 5px; color: #d4a2a2; }
.store-items-counter { position: absolute; top: 10px; left: 24px; display: block; height: 26px; min-width: 26px;
    text-align: center; color: #fff; background: #bf0b24; border-radius: 26px; line-height: 24px; }
.counter-zero { display: none; }
.store-items-in-cart { display: block; }
.store-book-link, .store-cart-link, .store-total-cart-amount { float: left; padding: 6px 12px; margin: 10px 10px 10px 0; }
.store-book-link { float: right; }
// product page
.store-product h1.store-product-name { margin: 40px 0 10px; }
.store-product .store-sale-price, .store-product .store-original-price, .store-product .store-list-price { padding: 5px 15px; background: #eee; text-decoration: none; }
.store-product label { color: darken($brand1, 5%); }
.store-product-primary-image img { box-shadow: $picture-shadow; margin-bottom: 20px; }
.store-product-additional-image { padding: 15px 10px !important; }
// basket modal and page
.store-whiteout .store-cart-modal { padding: 25px; width: 95%; top: 5%; max-height: 90%; }
.store-cart-modal h2 { color: $brand2; }
.store-cart-modal .store-qty-container, .store-cart-page .store-qty-container { min-width: 0; }
.store-cart-page h1, .store-order-complete-page h1 { margin-top: 40px; }
// checkout
.store-checkout-page label { color: darken($brand1, 5%); }
.scrollable { margin: 0; padding: 0; overflow: scroll; max-height: 160px; }
ul.store-checkout-totals-line-items li { background: #eee; border: none; text-align: right; }
div.store-whiteout { background: rgba(0, 0, 0, 0.5); }
@include media-breakpoint-up(md) {
    .store-utility-links { margin-bottom: 0; }
}
*/

// -------------------------------
//  CALENDAR BLOCK
// -------------------------------
.ccm-block-calendar-wrapper { margin-bottom: 30px; }
.fc-head, .fc-list-heading td { background: #eee !important; } // calendar header background
.fc-body, .fc-scroller, .fc-list-empty { background: #f7f7f7 !important; } // calendar body background
.fc .fc-toolbar button { padding: 0 7px; }  // top buttons
.fc-unthemed td.fc-today { background: #fafdc6; } // calendar current day
.fc-toolbar h2 { color: #b3b3b3; font-style: normal; font-size: 27px; } // month and year
.ccm-block-calendar-event-dialog-details > h3 { color: #bf0213 !important; margin-bottom: 10px !important; } // modal title
div.ccm-block-calendar-dialog-event-time { color: #fff !important; margin-bottom: 20px !important; background: #aaa; padding: 2px 10px; } // modal date
.ccm-block-calendar-event-dialog-details > div > .btn-primary { margin-bottom: 10px; } // link button
.fc-day-top a { color: $text-color; } // day date links
.fc-day-top a:hover { color: $link-hover-color; }


// -------------------------------
//  MISC CONTENT BLOCKS
// -------------------------------
// youtube block
.youtubeBlock,
// slideshare block
.slideshareBlock,
// image slider
.ccm-image-slider-container,
// image block
a.overlay-parent > picture > img.ccm-image-block.img-responsive,
div:not(.full-width-banner):not(.blog-header-img) > picture > img.ccm-image-block.img-responsive { margin-bottom: 20px; }
// image slider text and dots
.ccm-image-slider .ccm-image-slider-text { padding-top: 0; }
.ccm-image-slider .rslides_tabs { padding-top: 10px; }
// prev/next block at top of sidebar
.button-bar { margin-top: 10px; margin-bottom: 10px; }
.col-sidebar > .button-bar:first-child { margin-top: 0; margin-bottom: 30px; }
// push button bar to 100% width in sidebars
@include media-breakpoint-up(lg) {
    .col-sidebar .button-bar .btn-group { width: 100%; table-layout: fixed; border-collapse: separate; }
}
// share icons
.share-icons .list-inline { text-align: center; }
.share-icons .fab, .share-icons .fas { font-size: 140%; color: $gray-mid; }
.share-icons .fab:hover, .share-icons .fas:hover { color: $link-color; }
.share-icons .list-inline > li { display: inline-block; padding: 0 4px; }
@include media-breakpoint-up(md) {
    .share-icons .fab, .share-icons .fas { font-size: 120%; }
    .share-icons .list-inline > li { padding: 0 2px; }
}
@include media-breakpoint-up(xl) {
    .share-icons .fab, .share-icons .fas { font-size: 140%; }
}
// calendar
@include media-breakpoint-down(md) {
    .fc-toolbar .fc-center h2 { margin-top: 25px; }
}
// default page listing
.ccm-block-page-list-title { font-size: $h3-font-size * $font-size-reduction-tablets; font-family: $font-family-header; margin: 15px 0 5px; }
// 'Table' block (uses honsometables)
.htContextMenu.handsontable table.htCore tr > td { font-size: $font-size-small; }
.table_display .table-bordered > thead > tr > td, .table_display .table-bordered > thead > tr > th { border-bottom-width: 2px; }
.table_display tr > td.highlighted, .handsontable table.htCore td.highlighted { background: #ffa; }
// horizontal rule
hr { margin-top: 27px; margin-bottom: 27px; border-top: 1px solid #aaa; }
// googlemaps infoWindow - widen for phones
@include media-breakpoint-down(sm) {
    .gm-style-iw { min-width: 350px !important; }
}
// open karting intro page
.btn.btn-yellow { background: #f5ed33; color: #000; }
.btn.btn-blue { background: #26a8e2; color: #000; }
.btn.btn-green { background: #8ec641; color: #000; }
.btn.btn-orange { background: #f9b43d; color: #000; }
.btn.btn-yellow:hover { background: #d9d42e; }
.btn.btn-blue:hover { background: #208abb; }
.btn.btn-green:hover { background: #76a336; }
.btn.btn-orange:hover { background: #d49a34; }

// -------------------------------
// MISCELLANEOUS
// -------------------------------
//div#ccm-toolbar > ul li.ccm-logo span { padding-top: 4px !important; } // loggin-in dashboard logo
.space-5 { width: 5vw; display: inline-block; visibility: hidden; }
.nav-search-input { background: $nav-search-input; }
.no-margins { margin: 0 !important; }
.no-top-margin { margin-top: 0 !important; }
.no-bottom-margin { margin-bottom: 0 !important; }
.no-padding { padding: 0 !important; }
.no-border { border: none !important; }
.margin-bottom-0 { margin-bottom: 0 !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-bottom-20 { margin-bottom: 20px !important; }
.margin-bottom-30 { margin-bottom: 30px !important; }
.margin-bottom-40 { margin-bottom: 40px !important; }
.margin-bottom-50 { margin-bottom: 50px !important; }
.margin-top-0 { margin-top: 0 !important; }
.margin-top-10 { margin-top: 10px !important; }
.margin-top-20 { margin-top: 20px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-40 { margin-top: 40px !important; }
.margin-top-50 { margin-top: 50px !important; }
@media (max-width: 500px) {
    .margin-bottom-30 { margin-bottom: 20px !important; }
}
@include media-breakpoint-up(sm) {
    .col-pad-left-10 { padding-left: 10px !important; }
    .col-pad-right-10 { padding-right: 10px !important; }
}
.margin-right-10 { margin-right: 10px !important; }
.centre, .center { text-align: center !important; }
.tint-50 { opacity: .5; }
.hide, .hide-me { display: none; }
.nowrap { white-space: nowrap; }
.fa-lrg { font-size: 150%; }
.ccm-block-file .btn { margin: 0 10px 10px 0; } // file download button links 
.red, .red b { color: #be0703; }
.green, .green b { color: #390; }
.blue, .blue b { color: #1184d6; }
.grey, .grey b { color: #aaa; }
hr + h2 + h2 + p { margin-top: -15px; margin-bottom: 25px; } // event report, speaker profile, name
.no-styling { outline: none !important; box-shadow: none !important; }
.indent { padding-left: 15px !important; }
.image-caption { text-align: center; font-style: italic; color: #999; margin-top: 15px; }
.keyline-right { border-right: 1px solid #ddd; padding-right: 20px; margin-right: -10px; }


// changes specific for the c6 system 
//div#ccm-toolbar > ul > li#ccm-white-label-message { display: none !important; } // hide the 'powered by c5' header message
//#ccm-account-menu-container { display: none; } // hide the floating account menu as it sits behind the admin top menu
div.ccm-notifications-box { z-index: 1030; } // the 'page pending approval' alert box
.navbar-fixed-bottom, .navbar-fixed-top { z-index: 30; } // change BS's ridiculously high default
// c6 default form block submit button
.ui-dialog-buttonpane .btn.btn-primary { background-color: $success; border-color: darken($success, 5%); }
.ui-dialog-buttonpane .btn.btn-primary:hover { background-color: darken($success, 10%); }
// c6 block edit footer bar
html.ccm-toolbar-visible .ui-dialog-buttonpane { background-color: #d8e4ed; }

// -------------------------------
// ADMIN RESETS
// -------------------------------
.ccm-ui div p, .ccm-ui div ul { font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; font-size: 14px; }