/* -------------- SITE SYSTEM 6 THEME  Copyright 2024 Sinclair Design --------------- */
/* -------------------                 aos.scss                   ------------------- */
/* ---------------------------------------------------------------------------------- */
/* --------------------------- CUSTOM SCSS FOR CLIENT ------------------------------- */
/* ---------------------------------------------------------------------------------- */

// reduced set of v.2.1.1 options - see notes in scroll_gallery/view.php
[data-aos][data-aos][data-aos-duration='50'],
body[data-aos-duration='50'] [data-aos] {
    transition-duration: 50ms
}
[data-aos][data-aos][data-aos-delay='50'],
body[data-aos-delay='50'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='50'].aos-animate,
body[data-aos-delay='50'] [data-aos].aos-animate {
    transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration='100'],
body[data-aos-duration='100'] [data-aos] {
    transition-duration: .1s
}
[data-aos][data-aos][data-aos-delay='100'],
body[data-aos-delay='100'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='100'].aos-animate,
body[data-aos-delay='100'] [data-aos].aos-animate {
    transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration='200'],
body[data-aos-duration='200'] [data-aos] {
    transition-duration: .2s
}
[data-aos][data-aos][data-aos-delay='200'],
body[data-aos-delay='200'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='200'].aos-animate,
body[data-aos-delay='200'] [data-aos].aos-animate {
    transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration='300'],
body[data-aos-duration='300'] [data-aos] {
    transition-duration: .3s
}
[data-aos][data-aos][data-aos-delay='300'],
body[data-aos-delay='300'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='300'].aos-animate,
body[data-aos-delay='300'] [data-aos].aos-animate {
    transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration='400'],
body[data-aos-duration='400'] [data-aos] {
    transition-duration: .4s
}
[data-aos][data-aos][data-aos-delay='400'],
body[data-aos-delay='400'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='400'].aos-animate,
body[data-aos-delay='400'] [data-aos].aos-animate {
    transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration='500'],
body[data-aos-duration='500'] [data-aos] {
    transition-duration: .5s
}
[data-aos][data-aos][data-aos-delay='500'],
body[data-aos-delay='500'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='500'].aos-animate,
body[data-aos-delay='500'] [data-aos].aos-animate {
    transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration='600'],
body[data-aos-duration='600'] [data-aos] {
    transition-duration: .6s
}
[data-aos][data-aos][data-aos-delay='600'],
body[data-aos-delay='600'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='600'].aos-animate,
body[data-aos-delay='600'] [data-aos].aos-animate {
    transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration='700'],
body[data-aos-duration='700'] [data-aos] {
    transition-duration: .7s
}
[data-aos][data-aos][data-aos-delay='700'],
body[data-aos-delay='700'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='700'].aos-animate,
body[data-aos-delay='700'] [data-aos].aos-animate {
    transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration='800'],
body[data-aos-duration='800'] [data-aos] {
    transition-duration: .8s
}
[data-aos][data-aos][data-aos-delay='800'],
body[data-aos-delay='800'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='800'].aos-animate,
body[data-aos-delay='800'] [data-aos].aos-animate {
    transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration='900'],
body[data-aos-duration='900'] [data-aos] {
    transition-duration: .9s
}
[data-aos][data-aos][data-aos-delay='900'],
body[data-aos-delay='900'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='900'].aos-animate,
body[data-aos-delay='900'] [data-aos].aos-animate {
    transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration='1000'],
body[data-aos-duration='1000'] [data-aos] {
    transition-duration: 1s
}
[data-aos][data-aos][data-aos-delay='1000'],
body[data-aos-delay='1000'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1000'].aos-animate,
body[data-aos-delay='1000'] [data-aos].aos-animate {
    transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration='1100'],
body[data-aos-duration='1100'] [data-aos] {
    transition-duration: 1.1s
}
[data-aos][data-aos][data-aos-delay='1100'],
body[data-aos-delay='1100'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1100'].aos-animate,
body[data-aos-delay='1100'] [data-aos].aos-animate {
    transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration='1200'],
body[data-aos-duration='1200'] [data-aos] {
    transition-duration: 1.2s
}
[data-aos][data-aos][data-aos-delay='1200'],
body[data-aos-delay='1200'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1200'].aos-animate,
body[data-aos-delay='1200'] [data-aos].aos-animate {
    transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration='1300'],
body[data-aos-duration='1300'] [data-aos] {
    transition-duration: 1.3s
}
[data-aos][data-aos][data-aos-delay='1300'],
body[data-aos-delay='1300'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1300'].aos-animate,
body[data-aos-delay='1300'] [data-aos].aos-animate {
    transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration='1500'],
body[data-aos-duration='1500'] [data-aos] {
    transition-duration: 1.5s
}
[data-aos][data-aos][data-aos-delay='1500'],
body[data-aos-delay='1500'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1500'].aos-animate,
body[data-aos-delay='1500'] [data-aos].aos-animate {
    transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration='1700'],
body[data-aos-duration='1700'] [data-aos] {
    transition-duration: 1.7s
}
[data-aos][data-aos][data-aos-delay='1700'],
body[data-aos-delay='1700'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1700'].aos-animate,
body[data-aos-delay='1700'] [data-aos].aos-animate {
    transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration='1800'],
body[data-aos-duration='1800'] [data-aos] {
    transition-duration: 1.8s
}
[data-aos][data-aos][data-aos-delay='1800'],
body[data-aos-delay='1800'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='1800'].aos-animate,
body[data-aos-delay='1800'] [data-aos].aos-animate {
    transition-delay: 1.8s
}

[data-aos][data-aos][data-aos-duration='2000'],
body[data-aos-duration='2000'] [data-aos] {
    transition-duration: 2s
}
[data-aos][data-aos][data-aos-delay='2000'],
body[data-aos-delay='2000'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='2000'].aos-animate,
body[data-aos-delay='2000'] [data-aos].aos-animate {
    transition-delay: 2s
}
[data-aos][data-aos][data-aos-delay='3000'],
body[data-aos-delay='3000'] [data-aos] {
    transition-delay: 0
}
[data-aos][data-aos][data-aos-delay='3000'].aos-animate,
body[data-aos-delay='3000'] [data-aos].aos-animate {
    transition-delay: 3s
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
    transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translate(0)
}

[data-aos=fade-up] {
    transform: translateY(300px)
}

[data-aos=fade-down] {
    transform: translateY(-300px)
}

[data-aos=fade-right] {
    transform: translate(-300px)
}

[data-aos=fade-left] {
    transform: translate(300px)
}

[data-aos=fade-up-right] {
    transform: translate(-300px, 300px)
}

[data-aos=fade-up-left] {
    transform: translate(300px, 300px)
}

[data-aos=fade-down-right] {
    transform: translate(-300px, -300px)
}

[data-aos=fade-down-left] {
    transform: translate(300px, -300px)
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translate(0) scale(1)
}

[data-aos=zoom-in] {
    transform: scale(.6)
}

[data-aos=zoom-in-up] {
    transform: translateY(300px) scale(.6)
}

[data-aos=zoom-in-down] {
    transform: translateY(-300px) scale(.6)
}

[data-aos=zoom-in-right] {
    transform: translate(-300px) scale(.6)
}

[data-aos=zoom-in-left] {
    transform: translate(300px) scale(.6)
}

[data-aos=zoom-out] {
    transform: scale(1.2)
}

[data-aos=zoom-out-up] {
    transform: translateY(300px) scale(1.2)
}

[data-aos=zoom-out-down] {
    transform: translateY(-300px) scale(1.2)
}

[data-aos=zoom-out-right] {
    transform: translate(-300px) scale(1.2)
}

[data-aos=zoom-out-left] {
    transform: translate(300px) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
    transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translate(0)
}

[data-aos=slide-up] {
    transform: translateY(100%)
}

[data-aos=slide-down] {
    transform: translateY(-100%)
}

[data-aos=slide-right] {
    transform: translateX(-100%)
}

[data-aos=slide-left] {
    transform: translateX(100%)
}

[data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: transform
}

[data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0)
}
